<template lang="pug">
.FcwAccountSettingsAuthTemplate
  h4(v-html="authText")
  FForm.FcwAccountSettingsAuthTemplate__form(
    v-slot="{ submit }"
    :initial-values="formInitialValues"
    @valid="handleValidForm"
  )
    FGrid(gap="16")
      FGridItem.FcwAccountSettingsAuthTemplate__loginInputs(span="12")
        FInput.FcwAccountSettingsAuthTemplate__loginInput(
          v-if="isEmailOtpMode"
          name="email"
          :label="t('common__email')"
          :placeholder="t('forms__placeholder__email')"
          :rules="[required, email]"
          :error-message="t('forms__error_feedback__email')"
        )
        FcwPhoneInput.FcwAccountSettingsAuthTemplate__loginInput(
          v-else
          name="phone"
          :label="t('common__phone')"
          :rules="[required]"
          :error-message="t('forms__error_feedback__phone')"
          @country-code-change="trackCountryCodeChange"
        )
      FGridItem.FcwAccountSettingsAuthTemplate__formSubmit(span="12")
        FButton(
          size="medium"
          :loading="isLoading"
          @click="submit"
        ) 
          span {{ t('common__continue') }}
          FIcon(name="arrowRight")
</template>

<style lang="stylus">
.FcwAccountSettingsAuthTemplate
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 100%

  h4
    text-align center
    width 100%

.FcwAccountSettingsAuthTemplate__form
  width 100%

.FcwAccountSettingsAuthTemplate__loginInputs
  justify-self center
  width 100%

.FcwAccountSettingsAuthTemplate__loginInput
  margin-top rem(24)

.FcwAccountSettingsAuthTemplate__legalText
  justify-self center
  color var(--color--neutral--dark-3)
  max-width rem(440)

.FcwAccountSettingsAuthTemplate__formSubmit
  justify-self flex-end
</style>

<script setup lang="ts">
import { email, required } from '@fifteen/design-system-vue/rules';
import piwa from 'piwa';

import { useHcaptcha } from '@/composables/useHcaptcha';

const formInitialValues = {
  email: '',
  phone: '',
};

const isLoading = ref(false);

const authStore = useAuthStore();
const { clientState, isEmailOtpMode } = useClientStore();

const { t } = useClientI18n();
const api = useApi();
const route = useRoute();
const { triggerCaptcha, passcode } = useHcaptcha();

const { track } = useUserTracking();

const authText = computed(() =>
  isEmailOtpMode
    ? t('account_settings__auth__email__text')
    : t('account_settings__auth__phone__text')
);

async function handleValidForm(
  values: typeof formInitialValues
): Promise<void> {
  const { error: captchaError } = await piwa(triggerCaptcha());
  if (captchaError) return;

  isLoading.value = true;
  const { email, phone } = values;
  authStore.loginId = isEmailOtpMode ? email : phone;

  const { error } = await api.post('/client/auth/login', {
    body: {
      otpMode: clientState.otpMode,
      phone,
      email,
      hcaptchaPasscode: passcode.value,
    },
  });

  isLoading.value = false;

  if (error.value) {
    return useErrorAlert(error.value, { context: 'account-settings' });
  }

  await authStore.setAuthType('login');
  await navigateTo({
    name: 'account-settings-auth-confirmation',
    query: route.query,
  });
}

/**
 * We need to throttle the tracking of the country code change
 * to avoid sending a double event because of an unexpected behavior
 */
const trackCountryCodeChange = useThrottleFn(countryCode => {
  track.selectPhoneCountryCode({
    countryCode,
  });
}, 10);
</script>
